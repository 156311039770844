define("@fleetbase/fleetops-data/adapters/facilitator-vendor", ["exports", "@fleetbase/ember-core/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FacilitatorVendorAdapter extends _application.default {
    /**
     * Set the URL path for users endpoints
     *
     * @param {object} query
     * @return {String} originalUrl
     */
    urlForFindRecord(id, modelName, snapshot) {
      let baseUrl = this.buildURL('vendors/facilitators', id, snapshot);
      return baseUrl;
    }
  }
  _exports.default = FacilitatorVendorAdapter;
});