define("@fleetbase/dev-engine/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _routes.default)(function () {
    this.route('home', {
      path: '/'
    }, function () {
      this.route('index', {
        path: '/'
      });
    });
    this.route('api-keys', function () {
      this.route('index', {
        path: '/'
      });
    });
    this.route('webhooks', function () {
      this.route('index', {
        path: '/'
      });
      this.route('view', {
        path: '/:id'
      });
    });
    this.route('sockets', function () {
      this.route('index', {
        path: '/'
      });
      this.route('view', {
        path: '/:name'
      });
    });
    this.route('events', function () {
      this.route('index', {
        path: '/'
      });
      this.route('view', {
        path: '/:public_id'
      });
    });
    this.route('logs', function () {
      this.route('index', {
        path: '/'
      });
      this.route('view', {
        path: '/:public_id'
      });
    });
  });
});