define("@embroider/addon/index", ["exports", "@ember/debug", "@ember/application", "@embroider/addon/ember-private-api", "@ember/component/helper"], function (_exports, _debug, _application, _emberPrivateApi, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EnsureSafeComponentHelper = void 0;
  _exports.ensureSafeComponent = ensureSafeComponent;
  function ensureSafeComponent(value, thingWithOwner) {
    if (typeof value === 'string') {
      return handleString(value, thingWithOwner);
    } else if ((0, _emberPrivateApi.isCurriedComponentDefinition)(value)) {
      return value;
    } else if (value == null) {
      return value;
    } else {
      return handleClass(value, thingWithOwner);
    }
  }
  class EnsureSafeComponentHelper extends _helper.default {
    compute([value]) {
      return ensureSafeComponent(value, this);
    }
  }
  _exports.EnsureSafeComponentHelper = EnsureSafeComponentHelper;
  function handleString(name, thingWithOwner) {
    (true && !(false) && (0, _debug.deprecate)(`You're trying to invoke the component "${name}" by passing its name as a string. This won't work under Embroider.`, false, {
      id: 'ensure-safe-component.string',
      url: 'https://github.com/embroider-build/embroider/blob/master/ADDON-AUTHOR-GUIDE.md#when-youre-passing-a-component-to-someone-else',
      until: 'embroider',
      for: '@embroider/addon',
      since: '0.27.0'
    }));
    let owner = (0, _application.getOwner)(thingWithOwner);
    return (0, _emberPrivateApi.lookupCurriedComponentDefinition)(name, owner);
  }
  const classNonces = new WeakMap();
  let nonceCounter = 0;
  function ensureRegistered(klass, owner) {
    let nonce = classNonces.get(klass);
    if (nonce == null) {
      nonce = `-ensure${nonceCounter++}`;
      classNonces.set(klass, nonce);
      owner.register(`component:${nonce}`, klass);
    }
    return nonce;
  }
  function handleClass(klass, thingWithOwner) {
    let owner = (0, _application.getOwner)(thingWithOwner);
    let nonce = ensureRegistered(klass, owner);
    return (0, _emberPrivateApi.lookupCurriedComponentDefinition)(nonce, owner);
  }
});