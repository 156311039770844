define("ember-engines/components/link-to-component", ["exports", "@ember/routing/link-component", "@ember/application", "@ember/object", "@ember/utils", "@ember/debug", "@embroider/macros/es-compat2"], function (_exports, _linkComponent, _application, _object, _utils, _debug, _esCompat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // this file is only used when ember is older than 3.24
  // ember-engines/addon/-private/engine-ext.js

  let LinkTo;
  let LinkComponent;
  {
    let {
      LinkComponent: LegacyLinkComponent
    } = (0, _esCompat.default)(require("@ember/legacy-built-in-components"));
    LinkComponent = LegacyLinkComponent;
  }
  {
    (true && !(false) && (0, _debug.deprecate)(`Importing from 'ember-engines/components/link-to-component' is deprecated, please use '@ember/routing/link-component' directly`, false, {
      id: 'ember-engines.link-to-override',
      until: '0.9.0',
      for: 'ember-engines',
      since: {
        enabled: '0.8.10'
      }
    }));
    LinkTo = LinkComponent;
  }
  var _default = _exports.default = LinkTo;
});