define("@fleetbase/fleetops-data/serializers/payload", ["exports", "@fleetbase/ember-core/serializers/application", "@ember-data/serializer/rest"], function (_exports, _application, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PayloadSerializer extends _application.default.extend(_rest.EmbeddedRecordsMixin) {
    /**
     * Embedded relationship attributes
     *
     * @var {Object}
     */
    get attrs() {
      return {
        pickup: {
          embedded: 'always'
        },
        dropoff: {
          embedded: 'always'
        },
        return: {
          embedded: 'always'
        },
        waypoints: {
          embedded: 'always'
        },
        entities: {
          embedded: 'always'
        }
      };
    }
  }
  _exports.default = PayloadSerializer;
});