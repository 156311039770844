define("@fleetbase/fleetops-data/serializers/service-area", ["exports", "@fleetbase/ember-core/serializers/application", "@ember-data/serializer/rest"], function (_exports, _application, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ServiceAreaSerializer extends _application.default.extend(_rest.EmbeddedRecordsMixin) {
    /**
     * Embedded relationship attributes
     *
     * @var {Object}
     */
    get attrs() {
      return {
        zones: {
          embedded: 'always'
        }
      };
    }
    serializeHasMany(snapshot, json, relationship) {
      let key = relationship.key;
      if (key === 'zones') {
        return;
      } else {
        super.serializeHasMany(...arguments);
      }
    }
  }
  _exports.default = ServiceAreaSerializer;
});