define("ember-concurrency-test-waiter/with-test-waiter", ["exports", "@ember/debug", "ember", "@ember/test"], function (_exports, _debug, _ember, _test) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = withTestWaiter;
  let registered = false;
  let taskRunCounter = 0;

  // A function that, given a task property, will register it with the test
  // waiter so async test helpers will block anytime a task instance spawned
  // from that property is running.
  function withTestWaiter(taskProperty) {
    (true && !(Object.prototype.hasOwnProperty.call(taskProperty, 'taskFn')) && (0, _debug.assert)("withTestWaiter() will only work with ember-concurrency >=0.7.19 -- please upgrade", Object.prototype.hasOwnProperty.call(taskProperty, 'taskFn')));
    let originalTaskFn = taskProperty.taskFn;
    taskProperty.taskFn = function* (...args) {
      if (_ember.default.testing && !registered) {
        (0, _test.registerWaiter)(() => taskRunCounter === 0); // eslint-disable-line ember/no-legacy-test-waiters
        registered = true;
      }
      taskRunCounter += 1;
      try {
        return yield* originalTaskFn.apply(this, args);
      } finally {
        taskRunCounter -= 1;
      }
    };
    return taskProperty;
  }
});