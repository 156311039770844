define("@fleetbase/fleetops-data/transforms/multi-polygon", ["exports", "@ember-data/serializer/transform", "@fleetbase/fleetops-data/utils/geojson/multi-polygon", "@ember/utils"], function (_exports, _transform, _multiPolygon, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MultiPolygonTransform extends _transform.default {
    deserialize(serialized) {
      if ((0, _utils.isNone)(serialized)) {
        return serialized;
      }
      return new _multiPolygon.default(serialized);
    }
    serialize(deserialized) {
      if ((0, _utils.isNone)(deserialized)) {
        return deserialized;
      }
      return new _multiPolygon.default(deserialized);
    }
  }
  _exports.default = MultiPolygonTransform;
});