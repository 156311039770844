define("@fleetbase/fleetops-data/utils/geojson/geo-json", ["exports", "@fleetbase/fleetops-data/utils/geojson/calculate-bounds", "@ember/object"], function (_exports, _calculateBounds, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.RadiansPerDegree = _exports.MercatorCRS = _exports.GeographicCRS = _exports.EarthRadius = _exports.DegreesPerRadian = void 0;
  const EarthRadius = _exports.EarthRadius = 6378137;
  const DegreesPerRadian = _exports.DegreesPerRadian = 57.29577951308232;
  const RadiansPerDegree = _exports.RadiansPerDegree = 0.017453292519943;
  const MercatorCRS = _exports.MercatorCRS = {
    type: 'link',
    properties: {
      href: 'http://spatialreference.org/ref/sr-org/6928/ogcwkt/',
      type: 'ogcwkt'
    }
  };
  const GeographicCRS = _exports.GeographicCRS = {
    type: 'link',
    properties: {
      href: 'http://spatialreference.org/ref/epsg/4326/ogcwkt/',
      type: 'ogcwkt'
    }
  };
  const excludeFromJSON = ['length'];
  class GeoJson extends _object.default {
    toJSON() {
      var obj = {};
      for (var key in this) {
        if (Object.prototype.hasOwnProperty.call(this, key) && excludeFromJSON.indexOf(key) === -1) {
          obj[key] = this[key];
        }
      }
      obj.bbox = (0, _calculateBounds.default)(this);
      return obj;
    }
  }
  _exports.default = GeoJson;
});