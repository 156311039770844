define("@fleetbase/fleetops-data/adapters/customer", ["exports", "@fleetbase/ember-core/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CustomerAdapter extends _application.default {
    urlForQuery() {
      return `${this.host}/${this.namespace}/query/customers`;
    }
  }
  _exports.default = CustomerAdapter;
});